<template>
    <KCourse loader-class="MBcont" course-id="3" title="Роспись и парик" :items="items">
        <div class="MBtextcont">
            <h3>5.1 Схемы выкладки волос</h3>
            <p>Cмотрим урок, изучаем, применяем и активно думаем над прической для своей куклы. От вас требуется, чтобы вы поняли какой будет причёска, какие навыки и детали вы бы хотели изучить.</p>
        </div>

        <VideoView video-id="9a12a011d37a46ef88ca62e04b4ef08d"/>      

        <div class="MBtextcont">
             <h3>5.2 Трессы</h3>
        </div>

        <VideoView video-id="b4873bec8b554c87a3787795609f5a17"/>      

        <div class="MBtextcont">
             <h3>5.3 Парик с челкой и макушкой</h3>
             <p>Это последние уроки нашего марафона. И мне немного грустно... Но! Работа еще не закончена, а это значит, что впереди еще много нового и интересного. Хочу пожелать всем нам подольше сохранить в себе эту творческую атмосферу и радость от процесса. Остались лишь детали, но это порой самое вкусное!</p>
        </div>

        <VideoView video-id="149104aedb974174a8173b9608520971"/>      

        <div class="MBtextcont">
             <h3>5.4 Парик с прямым пробором</h3>
        </div>

        <VideoView video-id="0bcc4ab211b34b08971fa7e6e5907523"/>    

        <div class="MBtextcont">
             <h3>5.5 Пятипрядная коса, кудри, реснички</h3>
        </div>

        <VideoView video-id="2375617673ee439ead6b7373b9c4fa87"/>     

        <div class="MBtextcont">
            <h3>5.6 Оформляем прическу</h3>
            <p>И в догонку вам несколько идей, как можно оформить прическу )</p>
        </div>

        <VideoView video-id="b005e1c3e7ca41c7824d88a51fece143"/>     
        <VideoView video-id="81cec4fc8e70411c95bf240ef0ae5b8d"/>     

        <div class="MBtextcont">
            <p>Знания без практики сами по себе ничего не значат, поэтому я очень благодарна всем, кто сделал свою куклу вместе с нами! </p>
            <p>Теперь вы владеете бесценным опытом и вдохновением от собственного результата ) Очень важно именно сейчас не расплескать этот настрой, этот творческий поток и начать двигаться дальше… Исполнить свои мечты. Вполне вероятно, что мы увидимся с вами на выставках как участники, а не как зрители! И ваши куклы приобретут ваш неповторимый авторский стиль!</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Образ", url:"/mycourses/draw/1"},
                { title: "Подготовка", url:"/mycourses/draw/2"},
                { title: "Грунтуем и красим", url:"/mycourses/draw/3"},
                { title: "Расписываем", url:"/mycourses/draw/4"},
                { title: "Делаем парик", url:"/mycourses/draw/5"},
            ],
        }
        }
    }
</script>